import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";

import Layout from "../../components/Layout";
import ReactMarkdown from "react-markdown";
import Image from "../../components/image/Image";
import { BlogContext } from "../../contexts/BlogContext";

const BlogPost = () => {
    let params = useParams();

    const { posts } = useContext(BlogContext);
    const post = posts.find(
        (item) => item.title.replaceAll(" ", "-") == params.blogTitle
    );

    if (!post) {
        return <div></div>;
    }

    const date = Date.parse(post.date);
    const ye = new Intl.DateTimeFormat("en", {
        year: "numeric",
    }).format(date);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    return (
        <Layout>
            <div
                className="row"
                style={{ justifyContent: "center", alignContent: "center" }}
            >
                <div className="push-0 push-d-0 push-t-0 push-m-0 col-8 col-d-8 col-t-12 col-m-12">
                    <div class="post-head">
                        <div
                            class="article-tags__box"
                            style={{
                                justifyContent: "center",
                                marginTop: "30px",
                            }}
                        >
                            {post.tags
                                .substring(1, post.tags.length - 1)
                                .split(",")
                                .map((tag) => {
                                    return (
                                        <Link
                                            to="/tag/DESSERT"
                                            class="article__tag"
                                        >
                                            {tag}
                                        </Link>
                                    );
                                })}
                        </div>
                        <h1
                            className="text-center post-title"
                            style={{ marginTop: 0 }}
                        >
                            {post.title}
                        </h1>
                        <div
                            class="article__meta text-center"
                            style={{ justifyContent: "center" }}
                        >
                            <div class="article__author">
                                <Link
                                    to="/about/"
                                    class="article__author-image"
                                    style={{
                                        backgroundImage:
                                            "url(/images/002.webp)",
                                    }}
                                    aria-label="'s Picture"
                                ></Link>{" "}
                                <Link
                                    to="/about/"
                                    class="article__author-link"
                                ></Link>
                            </div>
                            <span class="article__date">
                                <time datetime={post.date}>
                                    {mo} {da}, {ye}
                                </time>
                            </span>
                        </div>
                    </div>
                    <Image
                        src={
                            post.image.startsWith("https://")
                                ? post.image
                                      .replaceAll('"/', "")
                                      .replaceAll('"', "")
                                : `/${post.image
                                      .replaceAll('"/', "")
                                      .replaceAll('"', "")}`
                        }
                        alt={`${post.title} image`}
                        height={500}
                        width={375}
                        style={{
                            maxHeight: "500px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                            marginBottom: "30px",
                        }}
                    />

                    <ReactMarkdown allowDangerousHtml={true}>
                        {post.html}
                    </ReactMarkdown>
                </div>
            </div>
        </Layout>
    );
};

export default BlogPost;
